import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn } from '../animations/animations';
import Box, { BoxProps } from '../Box/Box';

const MenuBox = styled(Box)<MenuProps>(({ align, theme }) => [
  css`
    top: calc(100% + ${theme.spacings[16]}px);
    animation: ${fadeIn} 200ms;
  `,
  align === 'left' &&
    css`
      left: 0;
    `,
  align === 'right' &&
    css`
      right: 0;
    `,
  align === 'center' &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `,
]);

export type MenuProps = {
  align?: 'center' | 'left' | 'right';
  children?: React.ReactNode;
  isOpen?: boolean;
  maxHeight?: BoxProps['maxHeight'];
  widthCustom?: BoxProps['widthCustom'];
};

// eslint-disable-next-line react/display-name
const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ align = 'center', isOpen = 'true', ...props }, forwardedRef) => (
    <MenuBox
      align={align}
      bg="surface.main"
      border="subdued"
      borderRadius="borderRadiusS"
      boxShadow="medium"
      overflowY="auto"
      position="absolute"
      visibility={isOpen ? 'visible' : 'hidden'}
      zIndex="2"
      {...props}
      ref={forwardedRef}
    />
  ),
);

export default Menu;
