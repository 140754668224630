import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Text from '@ui-v2/core/Text/Text';

export interface Props {
  canDecrement?: boolean;
  canIncrement?: boolean;
  decrement: () => void;
  id?: string;
  increment: () => void;
  minusIcon: ImageWithConfigFragment | null;
  plusIcon: ImageWithConfigFragment | null;
  value: number;
}

const Counter = ({
  canDecrement,
  canIncrement,
  decrement,
  id,
  increment,
  minusIcon,
  plusIcon,
  value,
}: Props) => {
  return (
    <Box alignItems="center" display="flex" gap={8}>
      <Button
        disabled={!canDecrement}
        icon={minusIcon}
        id={`${id}Decrement`}
        isRounded
        onClick={decrement}
        size="iconExtraSmall"
        type="button"
        variant="secondary"
      />
      <Box textAlign="center" widthCustom={18}>
        <Text as="div" variant="heading-5">
          {value}
        </Text>
      </Box>
      <Button
        disabled={!canIncrement}
        icon={plusIcon}
        id={`${id}Increment`}
        isRounded
        onClick={increment}
        size="iconExtraSmall"
        type="button"
        variant="secondary"
      />
    </Box>
  );
};

export default Counter;
