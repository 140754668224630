import { keyframes } from '@emotion/react';

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const slideDown = keyframes`
  from { height: 0; }
  to { height: var(--radix-collapsible-content-height); }
`;

export const slideUp = keyframes`
  from { height: var(--radix-collapsible-content-height); }
  to { height: 0; }
`;
