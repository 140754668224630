import { forwardRef } from 'react';
import InputBase, {
  InputPropsWithVariant,
} from '@ui-v2/core/Forms/Input/Input';
import { IconType } from '@ui-v2/types/typography';
import useGetIcon from '@web/context/hooks/useGetIcon';

export type InputProps = Omit<
  InputPropsWithVariant,
  'warningIcon' | 'iconLeft' | 'iconRight'
> & {
  iconLeft?: IconType;
  iconRight?: IconType;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ iconLeft, iconRight, ...props }: InputProps, forwardedRef) => {
    const getIcon = useGetIcon();

    return (
      <InputBase
        {...props}
        iconLeft={iconLeft ? getIcon(iconLeft) : null}
        iconRight={iconRight ? getIcon(iconRight) : null}
        ref={forwardedRef}
        warningIcon={getIcon('warningIcon')}
      />
    );
  },
);

export default Input;
