import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { useDebounce } from 'use-debounce';
import useLocalStorage from '@utils/hooks/useLocalStorage';
import { SearchState, StationListNode } from '@web/context/SearchWidgetContext';
import { filterAndSort } from '../helpers';

export const useStationPicker = ({
  defaultItems,
  onSelection,
  pickerType,
  searchState,
  selection,
}: {
  defaultItems: StationListNode[];
  onSelection: (item: StationListNode | null | undefined) => void;
  pickerType: SearchState;
  searchState: SearchState;
  selection: StationListNode | null;
}) => {
  const [resultsState, setResultsState] = useState<
    'idle' | 'no-results-found' | 'showing-results' | 'showing-cta'
  >('idle');

  const closeMenu = () => {
    setResultsState('idle');
    setItems([]);
  };

  const [getRecentSearches, setRecentSearches] = useLocalStorage<
    Array<StationListNode>
  >('search-widget-recent-searches', []);

  const addToRecentSearches = (item: StationListNode | null | undefined) => {
    const previous = getRecentSearches();

    if (item && !previous.find((x) => x.code === item.code)) {
      setRecentSearches([item, ...previous].slice(0, 3));
    }
  };

  const [items, setItems] = useState<Array<StationListNode>>([]);
  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    inputValue,
    selectedItem,
    setHighlightedIndex,
    setInputValue,
  } = useCombobox<StationListNode>({
    items,
    selectedItem: selection,
    itemToString: (item) => item?.value ?? '',
    onSelectedItemChange: ({ selectedItem }) => {
      // Storing the selected value for "recent searches"
      addToRecentSearches(selectedItem);

      // Close the menu
      closeMenu();

      onSelection(selectedItem);
    },
  });

  const [debouncedValue] = useDebounce(inputValue, 300);

  const setCTAMenu = () => {
    setResultsState('showing-cta');
    setItems(getRecentSearches());
  };

  useEffect(() => {
    /**
     * Sort items and open the menu if:
     * There's a value
     * That value is not the selected value
     * Otherwise close it
     */
    if (debouncedValue && inputValue && inputValue !== selectedItem?.value) {
      const filteredItems = filterAndSort(defaultItems, debouncedValue);

      if (filteredItems.length > 0) {
        setItems(filteredItems);
        setHighlightedIndex(0);
        setResultsState('showing-results');
      } else {
        setResultsState('no-results-found');
      }
    } else if (inputValue === '') {
      /**
       * Unsure what we want to do here.
       * In this scenario, the user has started a search and then
       * removed the search query, do we want to show the CTA menu again?
         setResultsState('showing-cta');
       */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  /**
   * The menu is open if:
   * The input has a value
   * That value is not the currently selected item
   */
  const isOpen =
    items.length > 0 || resultsState !== 'idle' || searchState === pickerType;

  const isShowingResults =
    isOpen && items.length > 0 && resultsState === 'showing-results';

  const isShowingNoResultsMessage =
    isOpen && resultsState === 'no-results-found';

  const isShowingCTAMenu = isOpen && resultsState === 'showing-cta';

  return {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    setInputValue,
    setResultsState,
    selectedItem,
    closeMenu,
    inputValue,
    isOpen,
    setCTAMenu,
    isShowingResults,
    isShowingNoResultsMessage,
    isShowingCTAMenu,
    items,
  };
};
